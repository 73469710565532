import React, { Fragment, useState } from "react"
import { IoCaretBackCircle, IoClose } from "react-icons/io5";
import { FaMicrophone } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import { post } from "../data";
import { LuDot } from "react-icons/lu";
import MainSearch from "../components/searchMain/MainSearch";
import PopularSearch from "../components/searchMain/PopularSearch";
import { useNavigate } from "react-router-dom";
import HealthSearch from "../components/searchMain/HealthSearch";
import NewsSearch from "../components/searchMain/NewsSearch";
import EntertainmentSearch from "../components/searchMain/EntertainmentSearch";
import { PiRecordFill } from "react-icons/pi";

export default function SearchMainPage() {
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("Main");
    const [search, setSearch] = useState(null);
    const [query, setQuery] = useState('');
    const [isListening, setIsListening] = useState(false);


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2.5,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
            slidesToSlide: 1
        }
    };

    const Category = [
        { id: 2, name: "Popular" },
        { id: 9, name: "Health" },
        { id: 10, name: "News" },
        { name: "Entertainment", id: 19 },
    ];
    const handleFocus = () => {
        setSearch(true);
    };

    const handleBlur = () => {
        setSearch(false);
    };

    const handleVoiceInput = () => {
        if (!('webkitSpeechRecognition' in window)) {
            alert('Your browser does not support speech recognition.');
            return;
        }

        const recognition = new window.webkitSpeechRecognition();
        recognition.continuous = false;
        recognition.interimResults = false;
        recognition.lang = 'en-US';

        recognition.onstart = () => {
            setIsListening(true);
        };

        recognition.onresult = (event) => {
            const speechResult = event.results[0][0].transcript;
            setQuery(speechResult);
            setIsListening(false);
            recognition.stop();
        };

        recognition.onerror = (event) => {
            console.error('Speech recognition error:', event.error);
            setIsListening(false);
            recognition.stop();
        };

        recognition.onend = () => {
            setIsListening(false);
        };

        recognition.start();
    };

    const handleChange = (e) => {
        setQuery(e.target.value);
    };


    return (
        <Fragment>
            <div className="text-center bg-[#d9d9d9]">
                <p className="text-red-700 text-[10px] uppercase font-bold">
                    C / FE / SEARCH {activeTab} PAGE / V1 / AUG 10, 2024
                </p>
                <p className="text-black text-[9px]">{copyright}</p>
            </div>
            <div className="grid grid-cols-12 bg-white mt-2 px-2">
                <div className="col-span-1 flex justify-start items-center">
                    <IoCaretBackCircle className="text-[1.3rem] text-black cursor-pointer" onClick={() => navigate(-1)} />
                </div>
                <div className={`${search ? "col-span-10" : "col-span-11"} relative`}>
                    <input
                        type="text"
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={query}
                        onChange={handleChange}
                        placeholder="Search..."
                        className="w-full border-2 focus:outline-none focus:ring-0 rounded-full bg-white px-2 py-1 pr-6"
                    />
                    {isListening ? <PiRecordFill className="absolute right-2 top-[10px] text-[1rem] cursor-pointer text-red-500" /> : <FaMicrophone onClick={handleVoiceInput} className="absolute right-2 top-[10px] text-[1rem] cursor-pointer text-gray-500" />
                    }

                </div>
                {search &&
                    <div className="col-span-1 flex justify-end items-center">
                        <IoClose className="text-red-700 text-[1.3rem] cursor-pointer" />
                    </div>
                }
            </div>
            {!search &&
                <>
                    <div className="grid grid-cols-12 bg-white mt-1 px-2 sticky top-0 z-50">
                        <div className="col-span-12">
                            <div className="px-1">
                                <Carousel
                                    arrows={true}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={false}
                                    responsive={responsive}
                                    ssr={true}
                                    infinite={false}
                                    slidesToSlide={1}
                                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                    className="gap-2 flex"
                                >
                                    {Category.map((data) => (
                                        <div className={`w-auto px-[6px] mx-1 py-[3px] cursor-pointer my-1 text-center text-gray-800 rounded-full ${activeTab === data.name ? "bg-blue-500 text-white rounded-full" : "bg-black rounded-full text-white"}`} key={data.id}>
                                            <p
                                                onClick={() => setActiveTab(data.name)}
                                                className={`cursor-pointer text-[12px] px-1`}
                                            >
                                                {data.name}
                                            </p>
                                        </div>
                                    ))}
                                </Carousel>
                            </div>

                        </div>
                    </div>
                    <hr className="w-full mt-[2px] mb-[2px] h-[0.5px] bg-[#c9c9c9] border-0" />
                </>
            }
            {search &&
                <div className="grid grid-cols-12 bg-white mt-2 px-2">
                    <h2 className="col-span-12 font-bold">History</h2>
                    <div className="col-span-12 flex flex-wrap gap-2 mt-1">
                        <p className=" col-span-4 bg-gray-300 rounded-full px-3 py-1 text-[12px]">
                            <span className="">@setPlayedSeconds</span>
                        </p>
                        <p className=" col-span-4 bg-gray-300 rounded-full px-3 py-1 text-[12px]">
                            <span className="">Search 2</span>
                        </p>
                        <p className=" col-span-4 bg-gray-300 rounded-full px-3 py-1 text-[12px]">
                            <span className="">Search 3</span>
                        </p>
                        <p className=" col-span-4 bg-gray-300 rounded-full px-3 py-1 text-[12px]">
                            <span className="">Search 3</span>
                        </p>
                        <p className=" col-span-4 bg-gray-300 rounded-full px-3 py-1 text-[12px]">
                            <span className="">Search 3</span>
                        </p>
                    </div>
                </div>
            }
            {search &&
                <div className="grid grid-cols-12 bg-white mt-2 px-2 mb-24">
                    <h2 className="col-span-12 font-bold mt-1">Recommended</h2>
                    <div className="col-span-12 flex flex-wrap gap-4 overflow-y-scroll">
                        <div className="mt-1">
                            {post.map((data) => (
                                <div
                                    key={data.id}
                                    className="flex gap-2 mb-1 cursor-pointer py-1"
                                >
                                    <img
                                        alt={data.userName}
                                        src={data.profileImage}
                                        className="h-8 w-8 rounded-full object-cover"
                                    />
                                    <div className="flex flex-col">
                                        <div className="flex items-center">
                                            <p className="text-sm font-bold">{data.firstName}</p>
                                            <p className="text-xs pl-1">{data.userName}</p>
                                            <LuDot className="h-3 w-3" />
                                            <p className="text-xs">{data.postTimeAgo}</p>
                                        </div>
                                        <p className="text-[10px] text-gray-600 leading-3 line-clamp-2 mr-4">{data.postMessage}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
            {activeTab === "Main" && !search && <MainSearch />}
            {activeTab === "Popular" && !search && <PopularSearch />}
            {activeTab === "Health" && !search && <HealthSearch />}
            {activeTab === "News" && !search && <NewsSearch />}
            {activeTab === "Entertainment" && !search && <EntertainmentSearch />}
        </Fragment>
    )
}

