import React from 'react'
import { IoClose } from "react-icons/io5";
import { Button } from "@material-tailwind/react";
import { previousOrders } from '../../data';


const EditProductModal = ({ close, editPostId }) => {
    const editPostData = previousOrders.filter((data) => (data.productOrderNumber === editPostId));
    const { productImg, productName, productPrice, date } = editPostData[0];

    return (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-50">
            <div className="bg-white px-2 py-4 rounded-lg shadow-lg w-11/12  h-100 max-w-lg">
                <div className="flex justify-between items-center">
                    <p className="text-xl font-bold">Edit Product</p>
                    <button className="bg-[#000000] hover:bg-[#222222] rounded p-1">
                        <IoClose className="cursor-pointer text-white text-[20px]" onClick={close} />
                    </button>
                </div>
                <div className="grid grid-cols-12 items-center mt-2 p-2  rounded-md">
                    <div className="col-span-12 overflow-x-scroll">
                        <img src={productImg} alt={productName} className="w-full rounded-2xl h-40 object-cover" />
                    </div>
                    <div className="col-span-12 py-1 flex text-[10px] text-gray-500 font-bold justify-start">
                        <p>{date}</p>
                    </div>
                    <div className="col-span-12 flex text-[16px] font-bold mt-1 justify-start">
                        {productName}
                    </div>
                </div>
                <div className="grid grid-cols-12 items-center mt-2 p-2 rounded-md">
                    <div className="col-span-6 flex text-[14px] font-bold justify-start">
                        Price
                    </div>
                    <div className="col-span-6 flex text-[16px] font-bold justify-start">
                        <input className="border-b-2 outline-none w-full" placeholder="$price" required value={productPrice} />
                    </div>
                </div>
                <div className="grid grid-cols-12 items-center mt-2 p-2 rounded-md">
                    <div className="col-span-6 flex text-[14px] font-bold justify-start">
                        Category
                    </div>
                    <div className="col-span-6 flex text-[16px] font-bold justify-start">
                        <input className="border-b-2 outline-none w-full" placeholder="Order Number" required value={"Electronics"} />
                    </div>
                </div>
                <div className="flex items-center mt-2 p-2 justify-center rounded-md">
                    <Button color="black" ripple="light" className="bg-black"><span>Update Post</span></Button>
                </div>
            </div>
        </div>
    );
};
export default EditProductModal
