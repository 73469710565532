import React, { useRef, useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { DecryptFn } from "../../hooks/DecryptFn";
import { recoverPassword, verifyOtp } from "../../redux/apislice/authSlice";

const EnterOtp = () => {
    const numberOfDigits = 6;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
    const [otpError, setOtpError] = useState(null);
    const otpBoxReference = useRef([]);
    const [timeLeft, setTimeLeft] = useState(360); // 6 minutes in seconds
    const [timerActive, setTimerActive] = useState(true);

    useEffect(() => {
        if (timeLeft === 0) {
            setTimerActive(false);
            return;
        }

        if (timerActive) {
            const intervalId = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [timeLeft, timerActive]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
            2,
            "0"
        )}`;
    };

    function handleChange(value, index) {
        let newArr = [...otp];
        newArr[index] = value;
        setOtp(newArr);

        if (value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    }

    function handleBackspaceAndEnter(e, index) {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            otpBoxReference.current[index - 1].focus();
        }
        if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    }

    const handlePaste = (event) => {
        const paste = event.clipboardData.getData("text");
        if (/^[0-9]{6}$/.test(paste)) {
            const newOtp = paste.split("");
            setOtp(newOtp);
            otpBoxReference.current.forEach((input, index) => {
                input.value = newOtp[index];
            });
        }
        event.preventDefault();
    };

    const decryptedEmail = DecryptFn(localStorage.getItem("email"));

    const handleVerifyOtp = useCallback(() => {
        const otpData = otp.join("");
        const payload = {
            email: decryptedEmail,
            otp: otpData,
        };

        const toastId = toast.loading("Verifying OTP...");
        try {
            dispatch(verifyOtp(payload))?.then((result) => {
                if (result.error) {
                    toast.error("Failed to verify OTP.", { id: toastId });
                    setOtpError("Invalid OTP. Please try again.");
                } else {
                    toast.success(result.payload.message, { id: toastId });
                    if (result.payload.message) {
                        setTimeout(() => {
                            navigate("/newpassword");
                        }, 3000);
                    }
                }
            });
        } catch (error) {
            toast.error("Failed to verify OTP.", { id: toastId });
            console.log("error", error);
        }
    }, [dispatch, navigate, otp, decryptedEmail ]);

    function validateEmail(email) {
        // Regular expression for validating an email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const resendOtp = async (e) => {
        e.preventDefault();
        if (validateEmail(decryptedEmail) && decryptedEmail.length > 0) {
            const toastId = toast.loading("Sending OTP...");
            try {
                const result = await dispatch(recoverPassword(decryptedEmail));
                if (result.error) {
                    toast.error(result.error, { id: toastId });
                } else {
                    toast.success("OTP sent successfully!", { id: toastId });
                    setTimeLeft(360)
                }
            } catch (error) {
                toast.error("Failed to send OTP.", { id: toastId });
                console.error("error", error);
            }
        } else {
            toast.error("Please enter a valid email address.");
        }
    };

    useEffect(() => {
        if (otp.join("").length === numberOfDigits) {
            handleVerifyOtp();
        } else {
            setOtpError(null);
        }
    }, [otp, handleVerifyOtp]);

    return (
        <div className="flex flex-col justify-center items-center px-4 mt-[4rem]">
            <Toaster position="top-right" />
            <p className="text-[#7000FF] font-bold text-[3.3rem] md:text-[40px] mb-3">
                Creative
            </p>
            <div className="h-[43vh] w-full rounded-[20px] p-4 bg-white overflow-hidden">
                <p className="font-bold text-[24px] md:text-[20px] text-center">
                    Enter Otp
                </p>
                <p className="text-[16px] md:text-[13px] text-center">
                    An OTP has been sent to your email address. Please check your inbox
                    and enter the code below to continue.
                </p>
                <form className="mt-7" onSubmit={resendOtp}>
                    <div className="w-full flex flex-col justify-center items-center">
                        <div className="flex items-center gap-2">
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    value={digit}
                                    maxLength={1}
                                    onChange={(e) => handleChange(e.target.value, index)}
                                    onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                                    ref={(reference) =>
                                        (otpBoxReference.current[index] = reference)
                                    }
                                    className={`border w-10 h-auto text-white p-2 rounded-md block bg-black focus:border-2 focus:outline-none appearance-none`}
                                    onPaste={handlePaste}
                                />
                            ))}
                        </div>
                        <p
                            className={`text-sm text-red-900 my-4 ${otpError ? "error-show" : ""
                                }`}
                        >
                            {otpError}
                        </p>
                    </div>
                    <div className="flex justify-center items-center">
                        {timerActive ? (
                            <p className="text-sm text-gray-600 mb-4">
                                Time remaining: {formatTime(timeLeft)}
                            </p>
                        ) : (
                            <p className="text-[12px] text-red-600 mb-4">
                                OTP has expired. Please request a new one.
                            </p>
                        )}
                    </div>

                    <div className="flex justify-between items-center mt-4">
                        <button
                            className="bg-[#7000FF] text-white w-[38%] rounded-md py-[10px] md:py-[5px]"
                            onClick={() => navigate("/recoverpassword")}
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            disabled={!timerActive ? false : true}
                            className="bg-[#7000FF] text-white w-[38%] rounded-md py-[10px] md:py-[5px] disabled:bg-[#c79fff]"
                        >
                            Resend Otp
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EnterOtp;
