import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { register } from "../../redux/apislice/authSlice";
import PulseLoader from "react-spinners/PulseLoader";

const SignupByEmail = () => {
   const [first_name, setFirstname] = useState("");
   const [last_name, setLastname] = useState("");
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [date_of_birth, setDateofbirth] = useState("");
   const [gender, setGender] = useState("");
   // const [userName, setUserName] = useState("");

   const navigate = useNavigate();

   const dispatch = useDispatch();
   const { loading } = useSelector((state) => state.auth);

   const handleSubmit = (e) => {
      e.preventDefault();

      let signupDetails = {
         first_name, last_name, email, password, date_of_birth, gender, /* username: userName */
      };

      dispatch(register(signupDetails)).then((result) => {
         if (result.error) {
            // toast.error("Signup failed! Please try again.");
            if (result.payload && result.payload.date_of_birth) {
               toast.error(result.payload.date_of_birth[0]);
            }
            if (result.payload && result.payload.email) {
               toast.error(result.payload.email[0]);
            }
         } else {
            toast.success("Signup successful! Please check your email to activate your account.");
            setTimeout(() => navigate("/login"), 3000);
         }
      });
   }

   return (
      <div className="flex flex-col justify-center items-center px-4">
         <p className="text-[#7000FF] font-bold text-[3.3rem] md:text-[40px]">Creative</p>
         <div className="h-[72vh] md:h-[70vh] w-full rounded-[20px] p-4 bg-white overflow-hidden">
            <Toaster
               position="top-right"
               reverseOrder={false}
            />
            <form onSubmit={handleSubmit}>
               <div className="mb-2">
                  <p className="text-gray-800 text-[15px] md:text-[13px]">First name</p>
                  <input
                     required
                     type="text"
                     value={first_name}
                     onChange={(e) => setFirstname(e.target.value)}
                     placeholder="Enter your first name"
                     className="w-full focus:outline-none border-gray-700 px-[8px] py-[10px] md:py-[7px] border-[1px] rounded-md"
                  />
               </div>
               <div className="mb-2">
                  <p className="text-gray-800 text-[15px] md:text-[13px]">Last name</p>
                  <input
                     required
                     type="text"
                     value={last_name}
                     onChange={(e) => setLastname(e.target.value)}
                     placeholder="Enter your last name"
                     className="w-full focus:outline-none border-gray-700 px-[8px] py-[10px] md:py-[7px] border-[1px] rounded-md"
                  />
               </div>
               <div className="mb-2">
                  <p className="text-gray-800 text-[15px] md:text-[13px]">Email</p>
                  <input
                     required
                     type="email"
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                     placeholder="Enter your email address"
                     className="w-full focus:outline-none border-gray-700 px-[8px] py-[10px] md:py-[7px] border-[1px] rounded-md"
                  />
               </div>
               <div className="mb-2">
                  <p className="text-gray-800 text-[15px] md:text-[13px]">Password</p>
                  <input
                     required
                     type="password"
                     value={password}
                     onChange={(e) => setPassword(e.target.value)}
                     placeholder="Enter your password"
                     className="w-full focus:outline-none border-gray-700 px-[8px] py-[10px] md:py-[7px] border-[1px] rounded-md"
                  />
               </div>
               {/* <div className="mb-2">
                  <p className="text-gray-800 text-[15px] md:text-[13px]">Username</p>
                  <input
                     required
                     type="text"
                     value={userName}
                     onChange={(e) => setUserName(e.target.value)}
                     placeholder="Enter your username"
                     className="w-full focus:outline-none border-gray-700 px-[8px] py-[10px] md:py-[7px] border-[1px] rounded-md"
                  />
               </div> */}
               <div className="mb-2">
                  <p className="text-gray-800 text-[15px] md:text-[13px]">
                     Date of Birth
                     <span className="border-black border-[1px] px-[3px] text-[9px] rounded-full ml-1 cursor-pointer">?</span>
                  </p>
                  <input
                     required
                     type="date"
                     value={date_of_birth}
                     onChange={(e) => setDateofbirth(e.target.value)}
                     placeholder="Enter your date of birth"
                     className="w-full focus:outline-none border-gray-700 px-[8px] py-[10px] md:py-[7px] border-[1px] rounded-md"
                  />
               </div>
               <div className="mb-2">
                  <p className="text-gray-800 text-[15px] md:text-[13px]">
                     Gender {"(optional)"}
                     <span className="border-black border-[1px] px-[3px] text-[9px] rounded-full ml-1 cursor-pointer">?</span>
                  </p>
                  <select
                     required 
                     value={gender}
                     onChange={(e) => setGender(e.target.value)}
                     className="w-full focus:outline-none border-gray-700 px-[8px] py-[10px] md:py-[8px] border-[1px] rounded-md"
                  >
                     <option>Select</option>
                     <option>Male</option>
                     <option>Female</option>
                     <option>Prefer not to answer</option>
                  </select>
               </div>

               <p className="text-[15px] md:text-[12px]">
                  By creating an account, I agree to the {" "}
                  <span className="text-[#00A3FF] hover:text-[#44afed] cursor-pointer" onClick={()=>navigate("/terms")}>Terms of Use,{" "}</span>
                  <span className="text-[#00A3FF] hover:text-[#44afed] cursor-pointer" onClick={()=>navigate("/privacy")}>Privacy Policy</span> and{" "}
                  <span className="text-[#00A3FF] hover:text-[#44afed] cursor-pointer" onClick={()=>navigate("/cookies")}>Cookies Policy</span>.
               </p>

               <button
                  type="submit"
                  className="w-full bg-[#7000FF] text-white rounded-md py-[10px] md:py-[5px] mt-2"
               >
                  {loading ?
                     <PulseLoader
                        color={"#fff"}
                        loading={loading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                     />
                     : "Start Now"}
               </button>

               <div className="flex items-center gap-1 mt-2">
                  <p className="text-[15px] md:text-[12px]">Know my account?</p>
                  <p className="text-[#00A3FF] hover:text-[#44afed] text-[15px] md:text-[12px] cursor-pointer" onClick={() => navigate("/login")}>Log in</p>
               </div>
            </form>
         </div>
      </div>
   )
}

export default SignupByEmail
