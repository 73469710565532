


import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegComment, FaRegHeart, FaHeart, FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import { LuRepeat2 } from "react-icons/lu";
import { FaRegBookmark, FaBookmark } from "react-icons/fa6";
import { FiBarChart2 } from "react-icons/fi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { groupPostData } from "../../data";
import { getPostDetails, postView } from "../../redux/apislice/postSlice";
import { useDispatch, useSelector } from "react-redux";
import { getComments, getReply } from "../../redux/apislice/commentSlice";
import ReactPlayer from "react-player";
import { GoMute, GoUnmute } from "react-icons/go";
import { postLike } from "../../redux/apislice/homeSlice";
import { startFollow } from "../../redux/apislice/profileSlice";
import Skeleton from "react-loading-skeleton";
import { IoCaretBackCircle } from "react-icons/io5";


const PostViewDetails = () => {
    const label = "C / FE / POST DETAILS / V1 / AUGUST 3, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { postId } = params;
    const [likes, setLikes] = useState(false);
    const [likeCount, setLikeCount] = useState(578);
    const [bookmarks, setBookmarks] = useState(false);
    const commentData = useSelector((state) => state.comments);
    const postDetails = useSelector((state) => state.post);
    const profile = useSelector((state) => state.profile);
    const { user, loading } = postDetails
    const [commentLikes, setCommentLikes] = useState(
        groupPostData.map((data) => ({
            id: data.id,
            likes: false,
            likeCount: data.likeCount || 0,
        }))
    );
    const [activeMenuId, setActiveMenuId] = useState(null);
    const [repliesVisible, setRepliesVisible] = useState({});
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setActiveMenuId(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    const handleLikeClick = async () => {
        setLikeCount(likes ? likeCount - 1 : likeCount + 1);
        setLikes(!likes);
        const response = await dispatch(postLike(postId));
        if (response.meta.requestStatus === 'fulfilled') {
            dispatch(getPostDetails(postId));
        } else {
            console.error('Like action failed');
        }
    };

    const handleCommentLikeClick = (commentId) => {
        setCommentLikes((prevCommentLikes) =>
            prevCommentLikes.map((comment) =>
                comment.id === commentId
                    ? {
                        ...comment,
                        likes: !comment.likes,
                        likeCount: comment.likes ? comment?.likeCount - 1 : comment?.likeCount + 1,
                    }
                    : comment
            )
        );
    };

    const toggleMenu = (commentId) => {
        setActiveMenuId((prevId) => (prevId === commentId ? null : commentId));
    };

    const toggleRepliesVisibility = (id) => {
        setRepliesVisible((prev) => ({
            ...prev,
            [id]: !prev[id]
        }));

        dispatch(getReply(id));
    };
    //FOLLOW FUNCTIONALITY
    const handleFollow = (user_id) => {
        dispatch(startFollow(user_id))
    }


    // VIDEO PLAYER FUNCTIONALITY
    const [playing, setPlaying] = useState({});
    const [muted, setMuted] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState({});
    const [duration, setDuration] = useState({});

    const handlePlayPause = (id) => {
        setPlaying(prev => {
            const newState = { ...prev };
            Object.keys(newState).forEach(key => {
                newState[key] = false;
            });
            newState[id] = !prev[id]; // Toggle between play and pause
            return newState;
        });
    };

    // SOUND FUNCTIONALITY
    const handleMute = () => {
        setMuted(!muted);
    };

    const handleProgress = (id, state) => {
        setPlayedSeconds(prev => ({ ...prev, [id]: state.playedSeconds }));
    };

    const handleDuration = (id, duration) => {
        setDuration(prev => ({ ...prev, [id]: duration }));
    };

    const formatDuration = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        const timeString = date.toISOString().substr(14, 5);
        return timeString;
    };

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        const timeString = date.toLocaleString('en-US', options);
        const dateString = date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }).replace(',', '');
        return `${timeString}, ${dateString}`;
    }
    const timestamp = user?.created_at;
    const formattedTime = formatTimestamp(timestamp);
    useEffect(() => {
        dispatch(getPostDetails(postId));
        dispatch(getComments(postId));
        dispatch(postView(postId));
    }, [dispatch, postId]);



    return (
        <div className="pb-28 relative">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p>
                <p className="text-black text-[9px]">{copyright}</p>
            </div>
            <div className="bg-white sticky top-0">
                <div className="px-2 flex items-center justify-between pt-2">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-black text-[25px] cursor-pointer"
                    />

                </div>

                <deiv className="h-[0.4px] bg-[#b2b2b2] w-full my-[6px]" />
            </div>

            {loading ? <>
                {[1].map((index) => (
                    <div className="mb-2 border border-gray-300 p-1" key={index}>
                        <div className="grid grid-cols-12">
                            <div className="col-span-2">
                                <Skeleton height={43} width={43} />
                            </div>
                            <div className="col-span-10">
                                <div className="flex flex-col">
                                    <div className="flex justify-between items-center">
                                        <div className="flex gap-1">
                                            <Skeleton width={100} />
                                            <Skeleton width={45} />
                                        </div>
                                        <Skeleton width={28} />
                                    </div>
                                    <Skeleton width={100} />
                                    <Skeleton count={1} />
                                </div>
                                <Skeleton height={125} />
                                <div className="flex justify-between items-center">
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                    <Skeleton height={25} width={25} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </> : <div className="px-2 py-1 border-b-[0.4px] border-[#b2b2b2] bg-white">
                {/* User name, tagname */}
                <div className="flex flex-col bg-white sticky top-0 z-10 pt-3 py-1 w-full">
                    <div className="flex items-start justify-between  ">
                        <div className="flex items-start gap-2">
                            <img
                                alt="user"
                                className="h-8 w-8 rounded-full object-cover"
                                src={user?.created_by?.avatar}
                            />
                            <div className="flex flex-col  leading-4">
                                <p className="text-black text-[12px] font-bold">{user?.created_by?.first_name} {user?.created_by?.last_name}</p>
                                <p className="text-[#5c5c5c] text-[12px] font-thin">{user?.created_by?.username}</p>
                            </div>
                        </div>
                        {profile?.user?.id !== user?.created_by?.id && <button className="text-white bg-black px-3 py-[3px] text-[12px] rounded-full mr-5" onClick={() => handleFollow(user.id)}>
                            Follow
                        </button>}
                        <BsThreeDotsVertical
                            onClick={() => toggleMenu(0)} // 0 to represent post menu
                            className="text-[#5c5c5c] cursor-pointer absolute -right-[6px]"
                        />
                        {activeMenuId === 0 && (
                            <div className="absolute top-0 right-2 bg-[#d9d9d9] py-1 rounded-md text-[12px]" ref={menuRef}>
                                <p className="px-3 cursor-pointer border-b-[0.4px] border-[#b2b2b2]" onClick={() => navigate(`/post/comment/${postId}`)}>comment</p>
                                <p className="px-3 cursor-pointer border-b-[0.4px] border-[#b2b2b2]">Report</p>
                                <p className="px-3 cursor-pointer">Block</p>
                            </div>
                        )}
                    </div>
                </div>
                {/* Post image */}
                <div className="flex flex-col bg-white">

                    <p className="text-black text-[12px] my-1">
                        {user?.body}
                    </p>
                </div>
                {user?.image && <img
                    alt="post"
                    src={user?.image}
                    className="w-full h-auto object-cover rounded-[6px]"
                />}
                {user?.video &&
                    <div className="mt-1 rounded-sm relative">
                        <ReactPlayer
                            url={user?.video}
                            playing={playing[user.id] || false}
                            muted={muted}
                            onProgress={(state) => handleProgress(user.id, state)}
                            onDuration={(duration) => handleDuration(user.id, duration)}
                            width="100%"
                            height="100%"
                        />
                        <button
                            onClick={() => handlePlayPause(user.id)}
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#00000052] text-[18px] p-[6px] rounded-full"
                        >
                            {playing[user.id] ? <FaPauseCircle className="text-[#ffffff84]" /> : <FaPlayCircle className="text-[#FF9900]" />}
                        </button>
                        <button
                            onClick={handleMute}
                            className="absolute bottom-2 right-2 text-[13px] text-white bg-[#0000008b] p-[4px] rounded-full"
                        >
                            {muted ? <GoMute /> : <GoUnmute />}
                        </button>
                        <button className="absolute bottom-2 left-2 bg-[#0000008b] text-[8px] text-white px-[4px] py-[2px] rounded-md">
                            {formatDuration(duration[user.id] - playedSeconds[user.id] || 0)}
                        </button>
                    </div>
                }
                {/* Like, Comment, Share, Bookmark */}
                <div className="flex items-center justify-between mt-2 text-[#4d4d4d]">
                    <div className="flex items-center cursor-pointer gap-1" onClick={() => navigate(`/post/comment/${postId}`)}>
                        <FaRegComment className="text-[14px]" />
                        <p className="text-[12px]">{user?.comments_count}</p>
                    </div>
                    <div className="flex items-center cursor-pointer gap-1" onClick={handleLikeClick}>
                        {likes ? <FaHeart className="text-[14px] text-red-500" /> : <FaRegHeart className="text-[14px]" />}
                        <p className="text-[12px]">{user?.likes_count}</p>
                    </div>
                    <div className="flex items-center cursor-pointer gap-1">
                        <LuRepeat2 className="text-[14px]" />
                        <p className="text-[12px]">12</p>
                    </div>
                    <div className="flex items-center cursor-pointer gap-1">
                        <FiBarChart2 className="text-[14px]" />
                        <p className="text-[12px]">{user?.view_count}</p>
                    </div>
                    <div className="flex items-center gap-2">
                        {bookmarks ?
                            <FaBookmark
                                className="text-[12px] text-blue-500 cursor-pointer"
                                onClick={() => setBookmarks(!bookmarks)}
                            /> :
                            <FaRegBookmark
                                className="text-[12px] cursor-pointer"
                                onClick={() => setBookmarks(!bookmarks)}
                            />}
                        <AiOutlineShareAlt
                            className="text-[14px] cursor-pointer"
                        />
                    </div>
                </div>
            </div>}
            {/* Post time */}
            <div className="px-2 py-1 border-b-[0.4px] border-[#b2b2b2]">
                <div className="flex items-center gap-1 text-[12px] text-[#4d4d4d]">
                    <p>{formattedTime.split(', ')[0]}</p>
                    <p>·</p>
                    <p>{formattedTime.split(', ')[1]}</p>
                    <p>·</p>
                    <p className="text-[#000000] font-bold">{user?.view_count} Views</p>
                </div>
            </div>
            {commentData.data?.map((data) => {
                const commentLike = commentLikes.find(comment => comment.id === data.id);

                return (
                    <div className="py-1 border-b-[0.4px] border-[#b2b2b2]" key={data.id}>
                        <div className="px-2 flex items-start justify-between relative">
                            <div className="flex items-start gap-2">
                                <img
                                    alt="user"
                                    className="h-7 w-7 rounded-full object-cover"
                                    src={data?.created_by?.avatar}
                                />
                                <div className="flex flex-col leading-[14px]">
                                    <p className="text-black text-[12px] font-bold">{data?.created_by?.first_name}</p>
                                    <p className="text-[#5c5c5c] text-[12px] font-thin">{data?.created_by?.username}</p>
                                </div>
                            </div>
                            <BsThreeDotsVertical
                                onClick={() => toggleMenu(data.id)}
                                className="text-[#5c5c5c] cursor-pointer absolute right-0"
                            />
                            {activeMenuId === data.id && (
                                <div className="absolute top-[-3px] right-[7px] bg-[#d9d9d9] py-1 rounded-md text-[12px]" ref={menuRef}>
                                    <p className="px-3 py-[1px] cursor-pointer border-b-[0.4px] border-[#b2b2b2]">Reply</p>
                                    <p className="px-3 py-[1px] cursor-pointer border-b-[0.4px] border-[#b2b2b2]">Report</p>
                                    <p className="px-3 py-[1px] cursor-pointer">Block</p>
                                </div>
                            )}
                        </div>
                        <div className="pt-1">
                            <p className="text-black text-[12px] px-2">{data?.body}</p>
                            <div className="flex items-center justify-between pt-[5px] px-2">
                                <p
                                    className="text-[10px] font-bold text-light-blue-500 cursor-pointer"
                                    onClick={() => toggleRepliesVisibility(data.id)}
                                >
                                    {repliesVisible[data?.id] ? "Hide replies" : "View replies"}
                                </p>
                                <div
                                    className="flex items-center cursor-pointer gap-1 text-[#4d4d4d]"
                                    onClick={() => handleCommentLikeClick(data.id)}
                                >
                                    {commentLike?.likes ? <FaHeart className="text-[12px] text-red-500" /> : <FaRegHeart className="text-[12px]" />}
                                    <p className="text-[10px]">{commentLike?.likeCount}</p>
                                </div>
                            </div>
                            {repliesVisible[data.id] && (<>


                                {commentData.replyData.map((reply) => (
                                    <div className="mt-2 pl-4 bg-[#d8d8d8]">
                                        <div className="px-2 pt-1 flex items-start justify-between relative">
                                            <div className="flex items-start gap-2">
                                                <img
                                                    alt="user"
                                                    className="h-7 w-7 rounded-full object-cover"
                                                    src={reply?.created_by.avatar}
                                                />
                                                <div className="flex flex-col leading-[14px]">
                                                    <p className="text-black text-[12px] font-bold">{reply?.created_by?.first_name}</p>
                                                    <p className="text-[#5c5c5c] text-[12px] font-thin">{reply?.created_by?.username}</p>
                                                </div>
                                            </div>
                                            <BsThreeDotsVertical
                                                className="text-[#5c5c5c] cursor-pointer absolute right-0"
                                            />
                                        </div>
                                        <div className="py-1 px-2">
                                            <p className="text-black text-[12px]">{reply?.body}</p>
                                        </div>
                                    </div>
                                ))}
                            </>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default PostViewDetails;
