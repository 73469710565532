import React, { Fragment, useEffect, useRef, useState } from "react";
import { FaChevronRight } from "react-icons/fa6";
import { IoMdAddCircleOutline, IoMdNotificationsOutline } from "react-icons/io";
import { IoCaretBackCircle, IoSettings } from "react-icons/io5";
import { MdAccountCircle } from "react-icons/md";
import { RiLogoutBoxLine, RiLogoutCircleRLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ShareModal from "../components/common/ShareModal";

const SettingsPage = () => {
   const navigate = useNavigate();
   const [isChecked2, setIsChecked2] = useState(false);
   const [isChecked3, setIsChecked3] = useState(false);
   const [language, setLanguage] = useState("English");
   const [country, setCountry] = useState('United States');
   const [showLanguage, setShowLanguage] = useState(false);
   const [showShareModel, setShareModalOpen] = useState(false);
   const [showCountry, setShowCountry] = useState(false);
   const langRef = useRef(null);
   const countryRef = useRef(null);


   const handleShowLanguage = () => setShowLanguage(!showLanguage)
   const handleSelectLanguage = (lang) => {
      setLanguage(lang);
      setShowLanguage(false);
   }

   const handleShowCountry = () => {
      setShowCountry(!showCountry);
      setShowLanguage(false);
   };
   const handleSelectCountry = (country) => {
      setCountry(country);
      setShowCountry(false);
   };

     const handleCheckbox2 = () => {
      setIsChecked2(!isChecked2);
   };
   const handleCheckbox3 = () => {
      setIsChecked3(!isChecked3);
   };

   const handleLogout = () => {
      localStorage.removeItem("securityAccessToken");
      localStorage.removeItem("accessToken");
      navigate("/");
   }

   const handleClickOutside = (event) => {
      if (langRef.current && !langRef.current.contains(event.target)) {
         setShowLanguage(false);
      }
      if (countryRef.current && !countryRef.current.contains(event.target)) {
         setShowCountry(false);
      }
   };

   useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
         document.removeEventListener('mousedown', handleClickOutside);
      };
   }, []);


   // Label & CopyRight
   const label = "C / FE / V3 / SETTINGS & PRIVACY PAGE/ SEP 02, 2024"
   const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

   return (
      <Fragment>
         <div className="bg-[#d9d9d9] text-center">
            <p className="text-red-700 text-[10px] uppercase font-semibold">{label}</p>
            <p className="text-black text-[9px]">{copyright}</p>
         </div>
         <div className="h-screen w-full">
            <div className=" sticky top-0 w-full bg-[black]  flex justify-between items-center px-2 py-1 gap-x-4 z-50">
               <IoCaretBackCircle
                  onClick={() => navigate(-1)}
                  className="text-white text-[1.3rem] cursor-pointer"
               />
               <p className="text-[18px] font-semibold text-white"> Settings & Privacy </p>
               <IoSettings className="text-white text-[1.3rem] cursor-pointer rotate-infinite " />
            </div>
            <div className="grid grid-cols-12 bg-white overflow-y-scroll mb-28 py-2 relative">
               <div className="col-span-12 flex items-center justify-center px-2 my-2">
                  <input
                     type="text"
                     placeholder="Search"
                     className="border-none outline-none text-black placeholder:text-gray-700 placeholder:p-1 bg-gray-300 w-full  rounded-full px-4 py-1"
                  />
               </div>
               <div className="col-span-12 px-2 grid grid-cols-12 mt-2 items-center">
                  <div className="col-span-1">
                     <MdAccountCircle className="text-[1.3rem] cursor-pointer" />
                  </div>
                  <h2 className="col-span-11 text-[18px] text-black  font-semibold">
                     Account
                  </h2>
                  <hr className="col-span-12 my-1 h-[0.5px] bg-gray-400 border-0" />
               </div>
               <div className="col-span-12 px-2">
                  <div className="grid grid-cols-12 items-center hover:bg-gray-100 hover:rounded-2xl cursor-pointer" onClick={() => navigate("/account/edit")}>
                     <h2 className="col-span-11 p-2 text-[16px] text-gray-600 font-medium">
                        Edit Profile
                     </h2>
                     <div className="col-span-1">
                        <FaChevronRight className="text-gray-500 text-[1.1rem] cursor-pointer" />
                     </div>
                  </div>
               </div>
               <div className="col-span-12 px-2">
                  <div className="grid grid-cols-12 items-center hover:bg-gray-100 hover:rounded-2xl cursor-pointer" onClick={() => navigate("/settings/changepassword")}>
                     <h2 className="col-span-11 p-2 text-[16px] text-gray-600 font-medium">
                        Change Password
                     </h2>
                     <div className="col-span-1">
                        <FaChevronRight className="text-gray-500 text-[1.1rem] cursor-pointer" />
                     </div>
                  </div>
               </div>

               <div className="col-span-12 px-2 grid grid-cols-12 mt-2 items-center">
                  <div className="col-span-1">
                     <IoMdNotificationsOutline className="text-[1.3rem] cursor-pointer" />
                  </div>
                  <h2 className="col-span-11 text-[18px] text-black   font-semibold">
                     Notifications
                  </h2>
                  <hr className="col-span-12 my-1 h-[0.5px] bg-gray-400 border-0" />
               </div>

               <div className="col-span-12 px-2 py-1">
                  <div className="grid grid-cols-12 items-center hover:bg-gray-100 hover:rounded-2xl cursor-pointer">
                     <h2 className="col-span-10 p-2 text-[16px] text-gray-600 font-medium">
                        App Notifications
                     </h2>
                     <div className="col-span-2">
                        <label className="relative inline-flex cursor-pointer select-none items-center">
                           <input
                              type="checkbox"
                              name="autoSaver"
                              className="sr-only"
                              checked={isChecked2}
                              onChange={handleCheckbox2}
                           />
                           <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked2 ? "bg-[#2ffd62]" : "bg-[#d9d9d9]"}`}>
                              <span
                                 className={`dot h-[13px] w-[13px] rounded-full ${isChecked2
                                    ? "bg-[#ffffff]"
                                    : "bg-black"} shadow-2xl duration-200 ${isChecked2 ? "translate-x-5" : ""}`}
                              />
                           </span>
                        </label>
                     </div>
                  </div>
               </div>
               <div className="col-span-12 px-2 ">
                  <div className="grid grid-cols-12 items-center text-[#2d4be2]">
                     <div className="col-span-1">
                        <IoMdAddCircleOutline className="text-[1.2rem] font-bold" />
                     </div>
                     <h2 className="col-span-11 text-[16px] text-[#2d4be2]  font-medium">
                        More
                     </h2>
                  </div>
                  <hr className="col-span-12 my-1 h-[0.5px] bg-gray-400 border-0" />
               </div>
               <div className="col-span-12 px-2 relative">
                  <div className="grid grid-cols-12 items-center hover:bg-gray-100 hover:rounded-2xl cursor-pointer">
                     <h2 className="col-span-7 p-2 text-[16px] text-gray-600 font-medium">
                        Language
                     </h2>
                     <div className="col-span-4 text-[12px] text-gray-300">
                        {language}
                     </div>
                     <div className="col-span-1">
                        <FaChevronRight className="text-gray-500 text-[1.1rem] cursor-pointer" onClick={handleShowLanguage} />
                     </div>

                     {showLanguage && <div ref={langRef} className="absolute right-2 top-4 h-24 overflow-y-scroll z-50 w-20 rounded-md">
                        {/* Language List */}
                        {
                           ["English", "Spanish", "Arabic", "Bengali", "Portuguese", "Russian", "Japanese", "Punjabi", "Marathi", "Telugu", "Turkish", "Chinese", " French", "Tagalog", "Vietnamese", "German", "Korean",].map((lang, index) => (
                              <p key={index} className="text-[12px]  p-2 py-1 text-white bg-black hover:bg-gray-400 cursor-pointer" onClick={() => handleSelectLanguage(lang)} >{lang}</p>
                           ))
                        }
                     </div>}
                  </div>
               </div>

               <div className="col-span-12 px-2 relative">
                  <div className="grid grid-cols-12 items-center hover:bg-gray-100 hover:rounded-2xl cursor-pointer">
                     <h2 className="col-span-7 p-2 text-[16px] text-gray-600 font-medium">
                        Country
                     </h2>
                     <div className="col-span-4 text-[12px] text-gray-300">
                        {country}
                     </div>
                     <div className="col-span-1">
                        <FaChevronRight className="text-gray-500 text-[1.1rem] cursor-pointer" onClick={handleShowCountry} />
                     </div>

                     {showCountry && (
                        <div ref={countryRef} className="absolute right-2 top-4 h-28 overflow-y-scroll z-50 w-28 rounded-md">
                           {["United States", "Canada", "United Kingdom", "Australia", "India", "Germany", "France", "Brazil", "China", "Japan", "Russia", "South Korea", "Mexico", "Italy", "Spain", "Turkey", "Netherlands", "Saudi Arabia"].map((country, index) => (
                              <p key={index} className="text-[12px] p-2 py-1 text-white bg-black hover:bg-gray-400 cursor-pointer" onClick={() => handleSelectCountry(country)}>
                                 {country}
                              </p>
                           ))}
                        </div>
                     )}
                  </div>
               </div>
               <div className="col-span-12 px-2 ">
                  <div className="grid grid-cols-12 items-center hover:bg-gray-100 hover:rounded-2xl cursor-pointer">
                     <h2 className="col-span-10 p-2 text-[16px] text-gray-600 font-medium">
                        Dark Mode
                     </h2>
                     <div className="col-span-2">
                        <label className="relative inline-flex cursor-pointer select-none items-center">
                           <input
                              type="checkbox"
                              name="autoSaver"
                              className="sr-only"
                              checked={isChecked3}
                              onChange={handleCheckbox3}
                           />
                           <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked3 ? "bg-[#2ffd62]" : "bg-[#d9d9d9]"}`}>
                              <span
                                 className={`dot h-[13px] w-[13px] rounded-full ${isChecked3
                                    ? "bg-[#ffffff]"
                                    : "bg-black"} shadow-2xl duration-200 ${isChecked3 ? "translate-x-5" : ""}`}
                              />
                           </span>
                        </label>                  </div>
                  </div>
               </div>
               <div className="col-span-12 px-2 ">
                  <div className="grid grid-cols-12 items-center hover:bg-gray-100 hover:rounded-2xl cursor-pointer" onClick={() => navigate("/blockedusers")}>
                     <h2 className="col-span-10 p-2 text-[16px] text-gray-600 font-medium">
                        Blocked Users
                     </h2>
                  </div>
               </div>
               <div className="col-span-12 px-2 ">
                  <div className="grid grid-cols-12 items-center hover:bg-gray-100 hover:rounded-2xl cursor-pointer" onClick={() => setShareModalOpen(true)}>
                     <h2 className="col-span-11 p-2 text-[16px] text-gray-600 font-medium">
                        Share with Friends
                     </h2>
                  </div>
               </div>
               <div className="col-span-12 px-2 ">
                  <div className="grid grid-cols-12 items-center hover:bg-gray-100 hover:rounded-2xl cursor-pointer" onClick={() => navigate("/privacy")}>
                     <h2 className="col-span-11 p-2 text-[16px] text-gray-600 font-medium">
                        Privacy Policy
                     </h2>
                     <div className="col-span-1">
                     </div>
                  </div>
               </div>
               <div className="col-span-12 px-2 ">
                  <div className="grid grid-cols-12 items-center hover:bg-gray-100 hover:rounded-2xl cursor-pointer"  onClick={() => navigate("/gethelp")}>
                     <h2 className="col-span-11 p-2 text-[16px] text-gray-600 font-medium">
                        Help
                     </h2>
                     <div className="col-span-1">
                     </div>
                  </div>
               </div>
               <div className="col-span-12 px-2  flex justify-center items-center">
                  <button class="bg-black text-white group items-center hover:translate-x-[-2px] flex space-x-2 transition-all duration-150 ease-in-out hover:bg-pink-400 shadow-md shadow-[#2d4be2] rounded-full px-4 py-1" onClick={() => handleLogout()}>
                     <span class="group-hover:text-white">Logout</span>
                     <span class="group-hover:hidden"><RiLogoutCircleRLine className="text-white text-[1.1rem] cursor-pointer font-semibold" /></span>
                     <span class="group-hover:block hidden"> <RiLogoutBoxLine className="text-white text-[1.1rem] cursor-pointer font-semibold" />
                     </span>
                  </button>
               </div>
            </div>
         </div>
         {showShareModel && (
            <ShareModal url={"http://group/owner/account"} onClose={() => setShareModalOpen(false)} />
         )}
      </Fragment>
   )
}

export default SettingsPage