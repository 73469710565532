import React, { Fragment, useState } from "react";
import Header from "./Header";
import { previousOrders } from "../../data";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import EditProductModal from "../common/EditProductModal";


const ProductPost = () => {
   const lable = "C / FE / YOUR PRODUCT POST PAGE / V1 / AUG 23, 2024";
   const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";
   const [editPost, setEditPost] = useState(false);
   const [editPostId, setEditPostId] = useState(null);
   const handleEditPost = (postId) => {
      setEditPostId(postId);
      setEditPost(!editPost);
   }
   return (
      <Fragment>
         <div className="bg-[#d9d9d9] text-center">
            <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            <p className="text-black text-[9px]">{copyright}</p>
         </div>
         <Header />
         <div className="px-2">
            <div className="mb-28">
               {previousOrders.map((data) => (
                  <div className="border-none border-gray-300 bg-gray-200 rounded-xl mb-4" key={data.id}>
                     <div className="grid grid-cols-12">
                        <div className="col-span-8 border-none border-gray-300">
                           <div className="flex flex-col p-[5px]">
                              <p className="text-[13px]">Post Placed: <span className="font-bold text-gray-900">{data.date}</span></p>
                              <p className="text-[13px]">Total: <span className="font-bold text-gray-900">${data.productPrice}</span></p>
                              <p className="text-[13px]">Sold No: <span className="font-bold text-gray-900">120</span></p>
                              <p className="text-[13px]">Post No: <span className="font-bold text-gray-900">#{data.productOrderNumber}</span></p>
                              <p className="text-[13px]">Category: <span className="font-bold text-gray-900">Electronics</span></p>
                           </div>
                        </div>
                        <div className="col-span-4 relative">
                           <div className="flex flex-col p-[5px]">
                              <img
                                 alt={data.productName}
                                 src={data.productImg}
                                 className="w-full h-[5.3rem] rounded object-cover img-shadow"
                              />
                              <p className="text-[10px] line-clamp-2 leading-3 pt-1">{data.productName}</p>
                              <BiSolidMessageSquareEdit className="text-blue-500 absolute top-2 right-2 cursor-pointer" onClick={() => handleEditPost(data?.productOrderNumber)} />
                           </div>
                        </div>
                     </div>
                  </div>
               ))}
            </div>
            {editPost && <EditProductModal close={handleEditPost} editPostId={editPostId} />}
         </div>
      </Fragment>
   )
}

export default ProductPost




