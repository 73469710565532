import React, { useState } from 'react';
import { FaLinkedin, FaFacebook } from 'react-icons/fa';
import { IoCaretBackCircle } from 'react-icons/io5';
import { useNavigate } from "react-router-dom";
import { BsFillCcCircleFill, BsFillCCircleFill } from 'react-icons/bs';
import RightSideMenu from './RightSideMenu';


export default function CustomerSupport() {
    const navigate = useNavigate();
    const [feedback, setFeedback] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
    };

    const label = "C / FE / CUSTOMER SUPPORT PAGE  / V1 / AUG 26, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    return (
        <div className='relative h-screen w-full'>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p>
                <p className="text-black text-[9px]">{copyright}</p>
            </div>
            <nav className="bg-blue-500 flex px-2 sticky top-0 p-2 justify-between items-center">
                <div className="flex items-center justify-center bg-[#00000085] hover:bg-[#0000009d] h-7 w-7 rounded-full">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-white cursor-pointer"
                    />
                </div>
                <h1 className="text-white text-2xl text-center font-bold">Customer Support</h1>
                <RightSideMenu />
            </nav>
            <div className='h-full'>
                <div className="px-2 mb-4">
                    {/* Header Section */}
                    <div className="mb-4 text-center">
                        <p className="text-[14px] text-gray-600">24x7 Customer Care Support – Here Whenever You Need Us</p>
                    </div>

                    {/* Introduction */}
                    <div className="mb-4">
                        <p className="text-[14px]">
                            Welcome to our Help Center! Our dedicated team is available around the clock to assist you with any questions,
                            concerns, or issues you may have. Whether you need help troubleshooting a problem, have a question about your
                            account, or need assistance with a recent purchase, we’re here to help.
                        </p>
                    </div>

                    {/* Support Channels */}
                    <div className="mb-4">
                        <h2 className="text-2xl font-semibold mb-4">Support Channels</h2>
                        <div className="mb-4">
                            <h3 className="text-md font-bold">Live Chat Support</h3>
                            <p className="text-sm font-bold">Available 24/7</p>
                            <p className='text-[14px] text-justify leading-tight py-1'>Our live chat agents are ready to assist you anytime, day or night. Click the chat icon at the bottom right of the screen to start a conversation with one of our support specialists.</p>
                        </div>

                        <div className="mb-4">
                            <h3 className="text-md font-bold">Phone Support</h3>
                            <p className="text-[14px]">24x7 Customer Care Hotline: +1 (800) 123-4567</p>
                            <p className='text-[14px] text-justify leading-tight py-1'>Prefer to speak directly with us? Give us a call anytime, and one of our friendly representatives will be happy to assist you.</p>
                        </div>

                        <div className="mb-4">
                            <h3 className="text-md font-bold">Email Support</h3>
                            <p className="text-[14px]">Email Us: <p href="mailto:support@yourcompany.com" className="text-blue-500">support@yourcompany.com</p></p>
                            <p className='text-[14px] text-justify leading-tight py-1'>If your inquiry isn’t urgent, feel free to send us an email. Our team aims to respond within 24 hours.</p>
                        </div>
                    </div>

                    {/* Self-Service Resources */}
                    <div className="mb-4">
                        <h2 className="text-2xl font-semibold mb-4">Self-Service Resources</h2>

                        <div className="mb-4">
                            <h3 className="text-md font-bold">Frequently Asked Questions (FAQs)</h3>
                            <p className='text-[14px] text-justify leading-tight py-1'>Find quick answers to common questions in our FAQ div. Whether you need help with billing, account management, or product information, our FAQs are a great place to start.</p>
                        </div>

                        <div className="mb-4">
                            <h3 className="text-md font-bold">Guides & Tutorials</h3>
                            <p className='text-[14px] text-justify leading-tight py-1'>Access step-by-step guides and tutorials designed to help you make the most of our services. From getting started to advanced troubleshooting, we’ve got you covered.</p>
                        </div>

                        <div className="mb-4">
                            <h3 className="text-md font-bold">Troubleshooting Articles</h3>
                            <p className='text-[14px] text-justify leading-tight py-1'>If you’re experiencing issues, our troubleshooting articles can help you resolve problems quickly and efficiently. Search by topic or keyword to find the information you need.</p>
                        </div>
                    </div>

                    {/* Account Management */}
                    <div className="mb-4">
                        <h2 className="text-2xl font-semibold mb-4">Account Management</h2>

                        <div className="mb-4">
                            <h3 className="text-md font-bold">Manage Your Account</h3>
                            <p className='text-[14px] text-justify leading-tight py-1'>Easily update your account information, change your password, or review your order history in the Account Settings div.</p>
                        </div>

                        <div className="mb-4">
                            <h3 className="text-md font-bold">Billing & Payments</h3>
                            <p className='text-[14px] text-justify leading-tight py-1'>Need help with billing or payment issues? Visit our Billing div for detailed information on how to view invoices, manage payment methods, and understand charges.</p>
                        </div>
                    </div>

                    {/* Order Support */}
                    <div className="mb-4">
                        <h2 className="text-2xl font-semibold mb-4">Order Support</h2>

                        <div className="mb-4">
                            <h3 className="text-md font-bold">Track Your Order</h3>
                            <p className='text-[14px] text-justify leading-tight py-1'>Wondering where your order is? Use our Order Tracking tool to see the status of your shipment and get real-time updates.</p>
                        </div>

                        <div className="mb-4">
                            <h3 className="text-md font-bold">Returns & Exchanges</h3>
                            <p className='text-[14px] text-justify leading-tight py-1'>If you need to return or exchange a product, visit our Returns & Exchanges div for detailed instructions on how to initiate the process.</p>
                        </div>
                    </div>

                    {/* Community Support */}
                    <div className="mb-4">
                        <h2 className="text-2xl font-semibold mb-4">Community Support</h2>
                        <p className='text-[14px] text-justify leading-tight py-1'>Join our community forums to connect with other customers, share experiences, and get advice. Our community is here to support you, too!</p>
                    </div>

                    {/* Feedback & Suggestions */}
                    <div className="mb-4">
                        <h2 className="text-2xl font-semibold mb-4">Feedback & Suggestions</h2>
                        <p className='text-[14px] text-justify leading-tight py-1'>We value your feedback! Let us know how we can improve your experience by submitting your thoughts through our Feedback form. Your input helps us serve you better.</p>
                        <form onSubmit={handleSubmit} className='flex flex-col justify-center items-center bg-gray-200 rounded-md p-2'>
                            <textarea
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                placeholder="Enter your feedback here..."
                                className="w-full h-24 border p-2 rounded-lg"
                                rows={5}
                            />
                            <button
                                type="submit"
                                className="bg-blue-500 text-white px-2 py-1 mt-2 rounded-sm hover:bg-orange-600"
                            >
                                Submit Feedback
                            </button>
                        </form>
                    </div>
                </div>
                <footer className="bg-gradient-to-r from-gray-800 to-gray-900 text-white pt-2 px-2 pb-24">
                    <div className="container mx-auto grid grid-cols-2 md:grid-cols-2 gap-8">
                        <div>
                            <h3 className="text-2xl font-bold mb-4">Contact Us</h3>
                            <p className='text-[14px]'>Company Name</p>
                            <p className='text-[14px]'>1234 Street Name</p>
                            <p className='text-[14px]'>City, State, ZIP</p>
                            <p className='text-[14px]'>(123) 456-7890</p>
                            <p className='text-[14px]'><a href="mailto:support@example.com" className="text-blue-500">support@example.com</a></p>
                            <button className="bg-blue-600 hover:bg-yellow-700 text-white text-[14px] font-bold py-1 px-2 rounded mt-2" onClick={() => navigate("/contactus")}>
                                Contact Us
                            </button>
                        </div>
                        <div>
                            <h3 className="text-2xl font-bold mb-4">Quick Links</h3>
                            <ul className="space-y-2 text-[14px]">
                                <li><p className="hover:underline">FAQ</p></li>
                                <li><p className="hover:underline">Support Center</p></li>
                                <li><p className="hover:underline">Shipping & Returns</p></li>
                                <li><p className="hover:underline">Order Tracking</p></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-2xl font-bold mb-4">Resources</h3>
                            <ul className="space-y-2 text-[14px]">
                                <li><p className="hover:underline">Help Articles</p></li>
                                <li><p className="hover:underline">Video Tutorials</p></li>
                                <li><p className="hover:underline">Documentation</p></li>
                                <li><p className="hover:underline">Community Forums</p></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-2xl font-bold mb-4">Follow Us</h3>
                            <div className="flex space-x-4 text-[14px]">
                                <p className="hover:text-gray-400"><FaFacebook /></p>
                                <p className="hover:text-gray-400"><BsFillCcCircleFill /></p>
                                <p className="hover:text-gray-400"><FaLinkedin /></p>
                                <p className="hover:text-gray-400"><BsFillCCircleFill /></p>
                            </div>
                            <div className="mt-6">
                                <button className="bg-blue-600 hover:bg-blue-700 text-white text-[14px] font-bold py-1 px-2 rounded">
                                    Sign Up for Newsletter
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="border-t border-gray-700 mt-8 pt-8 text-center text-sm">
                        <p>{copyright}</p>
                        <div className='flex justify-center items-center gap-1'>
                            <p className="hover:underline hover:text-white text-blue-500">Privacy Policy</p> |
                            <p className="hover:underline hover:text-white text-blue-500">Terms of Service</p> |
                            <p className="hover:underline hover:text-white text-blue-500">Careers</p>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}