import React, { Fragment, useState } from 'react'
import { IoCaretBackCircle, IoCloseCircle } from 'react-icons/io5';
import RightSideMenu from './RightSideMenu';
import { Input, Select, Option } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';


const Advertise = () => {
    const navigate = useNavigate();
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [discount, setDiscount] = useState('');
    const [tags, setTags] = useState('');
    const [image, setImage] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic, e.g., send data to backend
    };

    const handleSelectImage = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImage(reader.result);

        }
    }
    const label = "C / FE / ADVERTISE PAGE  / V1 / AUG 26, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{label}</p>
                <p className="text-black text-[9px]">{copyright}</p>
            </div>
            <div className='sticky top-0 bg-[#FF9900] flex justify-between items-center p-2 z-50'>
                <IoCaretBackCircle
                    className="text-black cursor-pointer text-[1.2rem]"
                    onClick={() => navigate(-1)}
                />
                <h1 className="text-xl text-center font-bold">Advertise Your Product</h1>
                <RightSideMenu />
            </div>
            <div className='pt-10'>
                <div className="px-2">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>

                            <Input required variant="static" label="Product Name" placeholder="Enter product name" type="text"
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)} />
                        </div>
                        <div>
                            <label className="block text-[14px] text-blue-gray-600 font-medium"> Description</label>
                            <textarea className='outline-none rounded-lg w-full resize-none border-gray-300 border-2 p-2' rows={4} placeholder="Enter product description" value={description}
                                onChange={(e) => setDescription(e.target.value)} />
                        </div>
                        <div>

                            <Select required variant="static" label="Category" placeholder={"Select Category"} >
                                <Option>Electronics</Option>
                                <Option>Fashiont</Option>
                                <Option>Homee</Option>
                                <Option>Beauty</Option>
                            </Select>
                        </div>
                        <div>

                            <Input required variant="static" label="Price$" placeholder="Enter product price" type="number"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)} />
                        </div>
                        <div>


                            <Input required variant="static" label="Discount%" placeholder="Enter discount percentage" type="number"
                                value={discount}
                                onChange={(e) => setDiscount(e.target.value)} />
                        </div>
                        <div>
                            <label className="block text-lg font-medium"></label>


                            <Select required variant="static" label="Condition" placeholder={"Select Condition"} >
                                <Option>New</Option>
                                <Option>Used</Option>
                                <Option>Refurbished</Option>

                            </Select>
                        </div>
                        <div>

                            <Input required variant="static" label="Tags" placeholder="Enter tags, separated by commas"
                                type="text"
                                value={tags}
                                onChange={(e) => setTags(e.target.value)} />
                        </div>
                        {image && <div className='w-full relative'>
                            <img src={image} alt="product" className="w-full object-cover h-20" />
                            <IoCloseCircle className='text-red-900 absolute -top-4 right-0 cursor-pointer' onClick={() => setImage(null)} />
                        </div>}
                        {!image && <div>
                            <Input required variant="static" label="Add Image" placeholder="Add product image"
                                type="file"
                                onChange={(e) => handleSelectImage(e)} />
                        </div>}
                        <div className='flex justify-center items-center'>
                            <button
                                type="submit"
                                className="px-3 py-2 bg-black text-white font-bold  rounded-full hover:bg-black-600"
                            >
                                Submit Advertise
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="mb-24 mt-4 pt-4 text-center text-sm">
                <p>{copyright}</p>
                <div className='flex justify-center items-center gap-1'>
                    <p className="hover:underline hover:text-white text-blue-500">Privacy Policy</p> |
                    <p className="hover:underline hover:text-white text-blue-500">Terms of Service</p> |
                    <p className="hover:underline hover:text-white text-blue-500">Careers</p>
                </div>
            </div>
        </Fragment>
    )
};

export default Advertise;

 