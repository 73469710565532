import React from "react";
import { MdEmail } from "react-icons/md";
import { FaSnapchatGhost, FaLinkedinIn, FaFacebook, FaYoutube, FaApple } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Signup = () => {
   const navigate = useNavigate();

   return (
      <div className="flex flex-col justify-center items-center px-4">
         <p className="text-[#7000FF] font-bold text-[3.3rem] md:text-[40px]">Creative</p>
         <p className="text-black font-bold text-[20px] md:text-[17px] mb-5 text-center">Signup to get Joy Watch <br />Fun Learn</p>
         <div className="w-full rounded-[20px] py-8 md:py-4 px-8 md:px-4 bg-white overflow-hidden">
            <div className="grid grid-cols-12 bg-[#d9d9d9] w-full py-[8px] md:py-[4px] cursor-pointer rounded-md" onClick={() => navigate("/signupbyemail")}>
               <div className="col-span-4">
                  <div className="flex justify-start items-center">
                     <p className="text-[#d9d9d9] bg-[#d9d9d9] h-8 w-8 md:h-7 md:w-7 rounded-full text-[15px] font-bold ml-4 md:ml-2 flex justify-center items-center">
                        <MdEmail />
                     </p>
                  </div>
               </div>
               <div className="col-span-5 flex justify-center items-center">
                  <p className="text-[16px] md:text-[14px] font-semibold">Email</p>
               </div>
            </div>
            <div className="grid grid-cols-12 bg-[#d9d9d9] w-full py-[8px] md:py-[4px] cursor-pointer rounded-md mt-2">
               <div className="col-span-4">
                  <div className="flex justify-start items-center">
                     <p className="text-[#00A3FF] bg-white h-8 w-8 md:h-7 md:w-7 rounded-full text-[15px] font-bold ml-4 md:ml-2 flex justify-center items-center">
                        CC
                     </p>
                  </div>
               </div>
               <div className="col-span-5 flex justify-center items-center">
                  <p className="text-[16px] md:text-[14px] font-semibold">Cardinal Cars</p>
               </div>
            </div>
            <div className="grid grid-cols-12 bg-[#d9d9d9] w-full py-[8px] md:py-[4px] cursor-pointer rounded-md mt-2">
               <div className="col-span-4">
                  <div className="flex justify-start items-center">
                     <p className="text-[#FFFFFF] bg-[#1877F2] h-8 w-8 md:h-7 md:w-7 rounded-full text-[15px] font-bold ml-4 md:ml-2 flex justify-center items-center">
                        <FaFacebook />
                     </p>
                  </div>
               </div>
               <div className="col-span-5 flex justify-center items-center">
                  <p className="text-[16px] md:text-[14px] font-[600]">Facebook</p>
               </div>
            </div>
            <div className="grid grid-cols-12 bg-[#d9d9d9] w-full py-[8px] md:py-[4px] cursor-pointer rounded-md mt-2">
               <div className="col-span-4">
                  <div className="flex justify-start items-center">
                     <p className="text-[#FFFFFF] bg-[#FFFC00] h-8 w-8 rounded-full text-[15px] font-bold ml-4 md:ml-2 flex justify-center items-center">
                        <FaSnapchatGhost />
                     </p>
                  </div>
               </div>
               <div className="col-span-5 flex justify-center items-center">
                  <p className="text-[16px] md:text-[14px] font-[600]">Snapchat</p>
               </div>
            </div>
            <div className="grid grid-cols-12 bg-[#d9d9d9] w-full py-[8px] md:py-[4px] cursor-pointer rounded-md mt-2">
               <div className="col-span-4">
                  <div className="flex justify-start items-center">
                     <p className="text-[#FFFFFF] bg-[#000000] h-8 w-8 rounded-full text-[15px] font-bold ml-4 md:ml-2 flex justify-center items-center">
                        <FaApple />
                     </p>
                  </div>
               </div>
               <div className="col-span-5 flex justify-center items-center">
                  <p className="text-[16px] md:text-[14px] font-[600]">Apple</p>
               </div>
            </div>
            <div className="grid grid-cols-12 bg-[#d9d9d9] w-full py-[8px] md:py-[4px] cursor-pointer rounded-md mt-2">
               <div className="col-span-4">
                  <div className="flex justify-start items-center">
                     <p className="text-[#FFFFFF] bg-[#FF0000] h-8 w-8 rounded-full text-[15px] font-bold ml-4 md:ml-2 flex justify-center items-center">
                        <FaYoutube />
                     </p>
                  </div>
               </div>
               <div className="col-span-5 flex justify-center items-center">
                  <p className="text-[16px] md:text-[14px] font-[600]">Youtube</p>
               </div>
            </div>
            <div className="grid grid-cols-12 bg-[#d9d9d9] w-full py-[8px] md:py-[4px] cursor-pointer rounded-md mt-2">
               <div className="col-span-4">
                  <div className="flex justify-start items-center">
                     <p className="text-[#FFFFFF] bg-[#2867B2] h-8 w-8 rounded-full text-[15px] font-bold ml-4 md:ml-2 flex justify-center items-center">
                        <FaLinkedinIn />
                     </p>
                  </div>
               </div>
               <div className="col-span-5 flex justify-center items-center">
                  <p className="text-[16px] md:text-[14px] font-[600]">Linked In</p>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Signup