import React from "react"
import Carousel from "react-multi-carousel";
import { post } from "../../data";
import { LuDot } from "react-icons/lu";
import { Button } from "@material-tailwind/react";
import { IoMdArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function MainSearch() {
    const navigate = useNavigate()
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    post.sort((a, b) => {
        if (a.firstName < b.firstName) return -1;
        if (a.firstName > b.firstName) return 1;
        return 0;
    });



    const uniquePosts = [
        {
            id: 1,
            img: "https://plus.unsplash.com/premium_photo-1714573189973-018546b10078?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDE4fE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D"
        },
        {
            id: 2,
            img: "https://images.unsplash.com/photo-1722562984868-f2b52f26ac72?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDQ0fE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D"
        },
        {
            id: 3,
            img: "https://images.unsplash.com/photo-1722660636530-bb860009ae5b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDU0fE04alZiTGJUUndzfHxlbnwwfHx8fHw%3D"
        },
    ];

    return (
        <div className="grid grid-cols-12 overflow-y-scroll">
            <div className="col-span-12">
                <Carousel
                    autoPlay={true}
                    arrows={true}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    infinite={true}
                    ssr={true}
                    slidesToSlide={1}
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                >
                    {uniquePosts.map((data) => (
                        <div className="rounded-md px-[8px] w-auto py-[3px] cursor-pointer my-1 text-center z-0" key={data.id}>
                            <img src={data.img} alt="img" className="w-full h-28 object-cover rounded-md" />
                        </div>
                    ))}
                </Carousel>
            </div>

            <div className="col-span-12 flex flex-wrap gap-4 px-2">
                <div className="mt-1 bg-gradient-to-tr from-teal-600 via-cyan-700 to-blue-800 h-16 w-full rounded-md flex justify-between items-center">
                    <div>
                        <p className="text-black font-bold text-[14px] px-2">
                            #WorldNews
                        </p>
                        <p className="text-black font-bold text-[11px] px-2">
                            1.2M Tweets
                        </p>
                    </div>
                    <div className="px-2" onClick={() => navigate(`/hashtag/WorldNews`)}>
                        <Button className="text-white  bg-gradient-to-tr from-teal-600 via-cyan-400 to-blue-800 rounded-full font-bold text-[14px] px-2 p-2  flex justify-center items-center"><IoMdArrowForward className="text-[1.3rem] text-black font-extrabold" /></Button>
                    </div>
                </div>
            </div>

            <div className="col-span-12 flex flex-wrap gap-4 mt-2 mb-24">
                <div className="mt-1">
                    {post.map((data) => (
                        <div
                            key={data.id}
                            className="flex gap-2 mb-2 cursor-pointer px-2 py-1"
                        >
                            <img
                                alt={data.userName}
                                src={data.profileImage}
                                className="h-8 w-8 rounded-full object-cover"
                                onClick={() => navigate("/otheruser/account/1a3962eb-e90f-407b-af82-76dcf360f570")}
                            />
                            <div className="flex flex-col" onClick={() => navigate("/otheruser/account/1a3962eb-e90f-407b-af82-76dcf360f570")}>
                                <div className="flex items-center" >
                                    <p className="text-sm font-bold" >{data.firstName}</p>
                                    <p className="text-xs pl-1">{data.userName}</p>
                                    <LuDot className="h-3 w-3" />
                                    <p className="text-xs">{data.postTimeAgo}</p>
                                </div>
                                <p className="text-[10px] text-gray-600 leading-3 line-clamp-2 mr-4">{data.postMessage}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
