import React, { useState } from "react";
import Header from "./Header";
import { biggestDiscountProducts, trendingPostData } from "../../data";
import Carousel from "react-multi-carousel";
import { FaArrowDownLong } from "react-icons/fa6";
import { IoHeartOutline, IoHeartSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";


const responsive = {
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1
   }
};
const Trending = () => {
   const lable = "C / FE / TRENDING PAGE / V1 / AUG 21, 2024";
   const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";
   const [trendingPost, setTrendingPost] = useState([]);
   const navigate = useNavigate()

   const handleClick = (data) => {
      if (trendingPost.find((item) => item === data)) {
         setTrendingPost(trendingPost.filter((item) => item !== data));
      } else {
         setTrendingPost([...trendingPost, data]);
      }
   }

   return (
      <div className="overflow-x-hidden">
         <div className="bg-[#d9d9d9] text-center">
            <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
            <p className="text-black text-[9px]">{copyright}</p>
         </div>
         <Header />
         <div className="-mx-[4px] px-2">
            <Carousel
               arrows={true}
               swipeable={true}
               draggable={true}
               showDots={false}
               responsive={responsive}
               ssr={true}
               infinite={true}
               removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
               className="pt-1"
            >
               {biggestDiscountProducts.map((data) => (
                  <div className="px-[4px] select-none relative" key={data.id}>
                     <img
                        src={data.imgUrl}
                        alt={data.name}
                        className="img-shadow w-full border-gray-400 border h-[6.4rem] rounded-xl object-cover cursor-pointer"
                     />
                     <p className=" absolute top-1 left-2 text-[9px] font-bold text-white bg-green-800 h-7 w-auto rounded-lg px-1 py-0 flex items-center gap-[1px]">
                        <FaArrowDownLong className="text-[10px]" /> {data.discount}%
                     </p>
                     <h3 className="text-[12px] font-semibold line-clamp-1 text-center">{data.category}</h3>
                  </div>
               ))}
            </Carousel>
         </div>
         <div className="px-2 my-2">
            <h1 className="text-[16px] font-bold line-clamp-1">Trending Products</h1>
         </div>
         <div className="grid grid-cols-3 md:grid-cols-2 gap-3 p-2 mb-24">
            {trendingPostData.map((product, index) => (
               <div key={index} className="relative bg-white shadow-md rounded-lg overflow-hidden">
                  <img src={product.image} alt={product.name} className="w-full h-20 object-cover cursor-pointer" onClick={() => navigate("/marketplace/productdetails")} />
                  <div className="absolute right-2 top-2 h-7 w-7 bg-gray-100 rounded-3xl bg-opacity-50 flex justify-center items-center">
                     {trendingPost.find((item) => item === product.ratings) ?
                        <IoHeartSharp className="cursor-pointer" color={"red"} onClick={() => handleClick(product.ratings)} /> :
                        <IoHeartOutline className=" text-black" onClick={() => handleClick(product.ratings)} />

                     }
                  </div>
                  <div className="p-2">
                     <h3 className="text-[12px] font-semibold line-clamp-1">{product.name}</h3>
                     <p className="text-[10px] text-gray-600 line-clamp-2">{product.description}</p>
                     <div className="flex justify-between items-center">
                        <span className="text-[14px] font-bold text-green-500">${product.sale_price}</span>
                        <span className="text-[12px] text-gray-400 line-through">${product.original_price}</span>
                     </div>
                     <div className="flex items-center justify-between">
                        <span className="text-black text-[12px] font-semibold">{product.ratings} <spna className="text-yellow-500">★</spna></span>
                        <span className="text-blue-600 text-[13px] font-bold">{product.offer_percentage}%off</span>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}

export default Trending

