import React, { useState } from 'react'
import { groupUserData } from '../../data';
import { useNavigate } from 'react-router-dom';
import { IoCaretBackCircle, IoCloseCircle } from 'react-icons/io5';
import BlockModal from '../common/BlockModal';

export default function BlockedUserList() {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [blockUser, setBlockUser] = useState(false);

    // Filter the blocked users based on the search term
    const filteredData = groupUserData.filter(user =>
        user.userName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className='h-screen w-full'>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">
                    C / FE / BLOCKED USERS LIST PAGE  / V1 / AUG 30, 2024
                </p>
                <p className="text-black text-[9px]">
                    Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
                </p>
            </div>

            <div className="sticky top-0 w-full bg-black z-50">
                <div className="px-2 flex justify-between items-center gap-3 py-1 border-b-[0.4px] border-[#242424]">
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="h-8 w-8 cursor-pointer text-white"
                    />
                    <div className="relative w-full">
                        <input
                            type="text"
                            value={searchTerm}
                            placeholder="Search for blocked users"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full focus:outline-none rounded-full pl-3 pr-9 py-1 text-white bg-[#2e2e2e]"
                        />
                        {searchTerm.length >= 1 && (
                            <IoCloseCircle
                                className="absolute top-1/2 right-1 transform -translate-y-1/2 text-[24px] text-black bg-white rounded-full p-1 cursor-pointer"
                            />
                        )}
                    </div>
                </div>
            </div>
            {filteredData.map((data) => (
                <div className="grid grid-cols-12 px-2 py-2 border-b-[0.4px] border-[#d7d7d7] hover:bg-gray-200" key={data.id}>
                    <div className="col-span-2">
                        <img
                            alt={data.userName}
                            src={data.userProfileImage}
                            className="h-8 w-8 object-cover rounded-full cursor-pointer"
                        />
                    </div>
                    <div className="col-span-10">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col leading-[16px]">
                                <div className="flex items-center gap-2">
                                    <p className="text-black text-[14px]">{data.userName}</p>
                                </div>
                                <p className="text-[#5c5c5c] text-[12px]">{data.userTagname}</p>
                            </div>
                            <button className="px-3 py-[2px] text-[12px] hover:font-bold font-normal rounded-full bg-blue-gray-100 text-black" onClick={() => setBlockUser(true)}>
                                unblock
                            </button>
                        </div>
                    </div>
                </div>
            ))}
            {filteredData.length === 0 && (<div className="text-center text-[#5c5c5c] mt-4">No blocked users</div>)}
            {blockUser && <BlockModal setBlockUser={setBlockUser} unblockUser={true} />}
        </div>
    )
}
