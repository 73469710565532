import React, { Fragment, useState } from 'react'
import Header from './Header';
import { trendingPostData } from '../../data';
import { IoHeartSharp } from 'react-icons/io5';
import { FaArrowDownLong } from "react-icons/fa6";
import { IoStar, IoStarHalf, IoStarOutline } from "react-icons/io5";

export default function FavoriteProducts() {
    const lable = "C / FE / FAVORITE PRODUCT POST PAGE / V1 / AUG 23, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";
    const [trendingPost, setTrendingPost] = useState([]);
    const handleClick = (data) => {
        if (trendingPost.find((item) => item === data)) {
            setTrendingPost(trendingPost.filter((item) => item === data));
        } else {
            setTrendingPost([...trendingPost, data]);
        }
    }
    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
                <p className="text-black text-[9px]">{copyright}</p>
            </div>
            <Header />
            <div className="grid grid-cols-3 md:grid-cols-2 gap-3 px-2 mb-24">
                {trendingPostData.map((product, index) => (
                    <div className='col-span-12 grid grid-cols-12'>
                        <div className='col-span-4 relative'>
                            <img src={product.image} alt={product.name} className="w-full h-20 object-cover rounded-lg" />
                            <div className="absolute right-2 top-2 h-6 w-6 bg-gray-100 rounded-3xl bg-opacity-50 flex justify-center items-center">
                                <IoHeartSharp className="cursor-pointer" color={trendingPost.find((item) => item === product.ratings) ? "red" : "black"} onClick={() => handleClick(product.ratings)} />
                            </div>
                        </div>
                        <div className='col-span-8'>
                            <div className="px-1 py-[1px]">
                                <h3 className="text-[12px] text-black font-semibold line-clamp-1">{product.name}</h3>
                                <p className="text-[10px] text-gray-600 line-clamp-2">{product.description}</p>
                                <div className="flex justify-between items-center">
                                    <div className='flex justify-between gap-2 items-center w-full' >
                                        <div className='flex items-center gap-1'>
                                            <p className="text-[9px] font-bold text-green-500 text-whiteh-7 w-auto rounded-lg px-1 py-0 flex items-center gap-[1px]">
                                                <FaArrowDownLong className="text-[10px] text-green-500" /> {product.offer_percentage}%
                                            </p>
                                            <span className="text-[14px] font-bold text-black">${product.sale_price}</span>
                                        </div>
                                        <span className="text-[12px] text-gray-400 line-through">${product.original_price}</span>
                                    </div>

                                </div>
                                <div className="flex items-center justify-between">
                                    <span className="text-black text-[12px] flex gap-1 font-semibold">{product.ratings} <spna className="text-yellow-500">
                                        <div className="flex justify-start items-center text-[11px] pt-[2px]">
                                            <IoStar className="text-yellow-800" />
                                            <IoStar className="text-yellow-800" />
                                            <IoStar className="text-yellow-800" />
                                            <IoStarHalf className="text-yellow-800" />
                                            <IoStarOutline className="text-yellow-800" />
                                        </div></spna></span>
                                    <span className="text-blue-600 text-[13px] font-bold">{product.offer_percentage}%off</span>
                                </div>
                            </div>
                        </div>
                        <hr className='col-span-12 my-2' />
                    </div>
                ))}
            </div>
        </Fragment>
    )
}


