import React, { useState, useEffect } from 'react';
import { Accordion, AccordionHeader, AccordionBody, Button } from '@material-tailwind/react';
import { FaFilter } from 'react-icons/fa';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

export default function FilterSearch() {
    const [filterItems, setFilterItems] = useState([]);
    const [open, setOpen] = useState(null);
    const navigate = useNavigate();

    function Icon({ id, open }) {
        return (
            <span>
                {open === id ? <FaChevronUp /> : <FaChevronDown />}
            </span>
        );
    }
    const handleOpen = (id) => {
        setOpen(open === id ? null : id);
    };

    const handleNavigate = (id) => {
        if (id === 1) {
            navigate(`/marketplace`);
        }
    }
    useEffect(() => {
        setFilterItems(
            [
                {
                    id: 1,
                    name: "Category",
                    options: ["Electronics", "Footwear", "Accessories", "Home Appliances"]
                },
                {
                    id: 2,
                    name: "Price",
                    options: ["Under $100", "$100 - $500", "$500 - $1000", "Above $1000"]
                },
                {
                    id: 3,
                    name: "Brand",
                    options: ["TechBrand", "WorkPro", "Sporty", "TimeKeep", "CoolHome"]
                },
                {
                    id: 4,
                    name: "InStock",
                    options: ["Yes", "No"]
                },
                {
                    id: 5,
                    name: "Rating",
                    options: ["1 Star", "2 Stars", "3 Stars", "4 Stars", "5 Stars"]
                },
                {
                    id: 6,
                    name: "Color",
                    options: ["Black", "Silver", "Red", "Brown", "White"]
                },
                {
                    id: 7,
                    name: "Material",
                    options: ["Glass and Metal", "Aluminum", "Mesh", "Leather", "Stainless Steel"]
                },
                {
                    id: 8,
                    name: "Size",
                    options: ["Small", "Medium", "Large"]
                },
                {
                    id: 9,
                    name: "Weight",
                    options: ["Lightweight", "Medium", "Heavy"]
                },
                {
                    id: 10,
                    name: "Warranty",
                    options: ["6 months", "1 year", "2 years", "5 years"]
                }
            ]);
    }, []);

    const lable = "C / FE / FILTER SEARCH PAGE / V1 / AUG 24, 2024";
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";

    return (
        <div className='flex flex-col h-full overflow-y-scroll relative'>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
                <p className="text-black text-[9px]">{copyright}</p>
            </div>
            <div className='flex justify-between items-center px-2 py-1 bg-[#FF9900] sticky top-0 z-50'>
                <div className='flex justify-center items-center gap-2'>
                    <h2 className='text-3xl font-bold'>Filter</h2>
                    <FaFilter className='text-gray-600 text-[1.2rem]' />
                </div>
                <div>
                    <AiFillCloseCircle onClick={() => navigate(-1)} className="text-[20px] cursor-pointer" />
                </div>
            </div>
            <div className='flex flex-col h-full px-2 mb-28'>
                {filterItems.map((item) => (
                    <Accordion key={item.id} open={open === item.id} icon={<Icon id={item.id} open={open} />}>
                        <AccordionHeader className='text-[14px]' onClick={() => handleOpen(item.id)}>
                            <p className='first-letter:uppercase'>
                                {item.name}
                            </p>
                        </AccordionHeader>
                        <AccordionBody className="flex flex-wrap justify-center gap-2">
                            {item.options.map((option, index) => (
                                <div key={index} className={`p-1 px-2 flex justify-center items-center gap-1 rounded-full ${false ? "bg-pink-600 text-white border-[2px]" : "bg-gray-200 border-[2px] text-black"}`}>
                                    <input
                                        type="radio"
                                        className="rounded-full accent-blue-700 cursor-pointer"
                                        checked={true} // Replace with dynamic condition
                                    />
                                    {option}
                                </div>
                            ))}
                        </AccordionBody>
                    </Accordion>
                ))}
            </div>
            <div className='sticky bottom-[5.9rem] bg-white gap-4 px-4 z-30 '>
                <div className='w-full flex p-1 justify-center gap-4 items-center'>
                    <Button variant='gradient' color='' className='rounded-sm py-2'>
                        Cancel
                    </Button>
                    <Button variant='gradient' color='blue' className='bg-blue-600 rounded-sm py-2 w-full' onClick={() => handleNavigate(1)}>
                        Apply Filter
                    </Button>
                </div>
            </div>
        </div>
    );
}
