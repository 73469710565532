import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";

const mainUrl = REACT_APP_MAIN_URL;

export const getHomeData = createAsyncThunk(
    "homeAllPost",
    async (page_Count, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.get(`${mainUrl}/api/post/list/${page_Count ? `?page=${page_Count}` : ""}`, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const postLike = createAsyncThunk(
    "postLikes",
    async (postId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const response = await axios.post(`${mainUrl}/api/post/like/${postId}/`, {}, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const homeSlice = createSlice({
    name: "home",
    initialState: {
        loading: false,
        data: {
            count: 0,
            next: null,
            previous: null,
            results: []
        },
        error: null,
    },

    extraReducers: (builder) => {
        // Fetch Homepage All Post Data
        builder.addCase(getHomeData.pending, (state) => {
            state.loading = true;
            state.data = [];
            state.error = null;
        });
        builder.addCase(getHomeData.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload
            state.error = null;
        });
        builder.addCase(getHomeData.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.payload;
        });

        // Post Like
        builder.addCase(postLike.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(postLike.fulfilled, (state) => {
            state.error = null;
        });
        builder.addCase(postLike.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default homeSlice.reducer;
