


import { Card } from "@material-tailwind/react";
import React from "react";
export default function ContectUsPage() {

    return (
        <div className='grid grid-cols-12 overflow-y-scroll bg-gray-200'>
            <div className="col-span-12 bg-[#D9D9D9]  h-6">
                <p className="text-red-700 text-[10px] font-bold text-center pt-[4px]">
                    C CONTACT US PAGE - FE - V1 - AUG 14, 2024
                </p>
            </div>
            <div className="col-span-12 relative w-full">
                <img src="https://media.istockphoto.com/id/1450058572/photo/businessman-using-a-laptop-and-touching-on-virtual-screen-contact-icons-consists-of-telephone.jpg?s=2048x2048&w=is&k=20&c=7KhNOYQ1PAxuAcVxu_YieJo5wvIvBpcw1nkXDJ5YM30=" alt="Contact Us" className="mx-auto" />

            </div>
            <div className="col-span-12 flex justify-center items-center ">

                <Card className="w-[90%] mt-4 py-2" >
                    <form className=" px-2 mt-4">
                        <div class=" bg-white flex flex-col justify-center items-center w-full ">
                            <h2 class="text-gray-900 text-2xl mb-1 font-bold">Get In Touch!</h2>
                            <p class="leading-relaxed text-[12px] mb-5 text-gray-600 text-center">We’re here to help! Whether you have a question, feedback, or need support, feel free to reach out. Our team is always ready to assist you
                            </p>
                            <div class="relative mb-4">
                                <label for="name" class="leading-7 text-sm text-gray-600">Name</label>
                                <input type="text" id="name" name="name" class="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            </div>
                            <div class="relative mb-4">
                                <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
                                <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                            </div>
                            <div class="relative mb-4">
                                <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                                <textarea id="message" name="message" class="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                            </div>
                            <button class="text-white rounded-lg text-center  px-6 py-2 bg-red-500 border-0 p-4 focus:outline-none hover:bg-red-600">
                                Send
                            </button>
                        </div>
                    </form>
                </Card>
            </div>

            <div className="col-span-12 px-2 mb-28">

                <h1 className="text-[24px] font-bold my-2 text-center">Office Location
                </h1>
                <p className="text-center text-[13px] text-gray-600">
                    Visit us at our headquarters or send us mail at the address below. We’re happy to meet with you by appointment.
                </p>
                <div className="grid grid-cols-12 mt-4 ">
                    <div className="col-span-4 text-[14px] font-bold ">
                        Address:
                    </div>
                    <div className="col-span-8 text-[13px] font-bold ">
                        123 Your Street, Your City, Your State, ZIP Code
                    </div>

                   
                    <div className="col-span-4 text-[14px] font-bold ">
                        Office Hours:
                    </div>
                    <div className="col-span-8 text-[13px] font-bold ">
                        Monday - Friday, 9:00 AM - 5:00 PM (EST)

                    </div>
                    <div className="col-span-4 text-[14px] font-bold ">
                        Directions:
                    </div>
                    <div className="col-span-8 text-[13px] font-bold ">
                        Get Directions
                    </div>


                </div>

            </div>
        </div>
    );
}
