import React, { useEffect } from "react";
import helmetImg from "../assets/7552d5ec4214add42e5e9270beeedd0a.png";
import notificationImg from "../assets/bc4591f3564d7433335488fee09a5e18.png";
import postImg from "../assets/58cda8b3c8fdbe07eb9f2353c4f300ba.png";
import homeImg from "../assets/86a9c46abe6250364fc1569cb8239f82.png";
import advertiseImg from "../assets/65d370f1f991e43161140f8d8146a4d9.png";
import { useNavigate } from "react-router-dom";
import { fetchUserNotifications } from "../redux/apislice/notificationsSlice";
import { useDispatch, useSelector } from "react-redux";

const Footer = () => {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const notifications = useSelector(state => state.notifications)
   const notifyCount = notifications.data?.filter(notify => notify.is_read === false)?.length

   useEffect(() => {
      dispatch(fetchUserNotifications())
   }, [dispatch])

   return (
      <div>
         <div className="bg-[#a5a5a5] px-4">
            <div className="grid grid-cols-12 items-center text-white py-[8px]">
               <div className="col-span-8">
                  <button className="text-black bg-[#FFF500] text-[16px] md:text-[13px] font-bold w-[95%] py-[7px] cursor-pointer rounded-[5px]">
                     Secret sauce panel
                  </button>
               </div>
               <div className="col-span-4">
                  <button className="text-black bg-[#E4080A] text-[16px] md:text-[13px] font-bold w-full py-[7px] cursor-pointer rounded-[5px]">
                     Invite
                  </button>
               </div>
            </div>
         </div>
         <div className="bg-black px-4 py-2">
            <div className="flex justify-between items-center">
               <img
                  alt="helmetImg"
                  src={helmetImg}
                  className="h-[30px] w-[30px] md:h-6 md:w-6 cursor-pointer object-cover"
               />
               <div className="relative">
                  <img
                     alt="notificationImg"
                     src={notificationImg}
                     onClick={() => navigate("/notification")}
                     className="h-[30px] w-[30px] md:h-6 md:w-6 cursor-pointer object-cover"
                  />
                  {notifyCount > 0 && (<div className="absolute -top-1 -right-1 bg-[red] text-white rounded-full h-4 w-4 flex justify-center items-center text-[8px]">{notifyCount}</div>)}
               </div>
               <img
                  alt="postImg"
                  src={postImg}
                  onClick={() => navigate("/post")}
                  className="h-[35px] w-[35px] md:h-[27px] md:w-[27px] cursor-pointer object-cover"
               />
               <img
                  alt="homeImg"
                  src={homeImg}
                  onClick={() => navigate("/home")}
                  className="h-[40px] w-[40px] md:h-[30px] md:w-[30px] cursor-pointer object-cover"
               />
               <img
                  alt="advertiseImg"
                  src={advertiseImg}
                  className="h-[30px] w-[30px] md:h-6 md:w-6 cursor-pointer object-cover"
               />
            </div>
         </div>
      </div>
   )
}

export default Footer