import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getTashTags } from '../../redux/apislice/hashtagSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IoCaretBackCircle } from 'react-icons/io5';
import Carousel from 'react-multi-carousel';
import ForYou from './ForYou';
import Tags from './Tags';


const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        slidesToSlide: 1
    }
};
export default function HashtagMain() {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { tagname } = params
    const hashtag = useSelector(state => state.hashTag)
    const [activeTab, setActiveTab] = useState("For You");


    const Category = [
        { id: 2, name: "For You" },
        { id: 10, name: "Tags" },
    ];


    useEffect(() => {
        dispatch(getTashTags(tagname))

    }, [dispatch, tagname])
    const lable = `C / FE / ${activeTab === "For You" ? "HASHTAG MAIN" : activeTab}   / V1 / AUG 22, 2024`;
    const copyright = "Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc";


    return (
        <div className='h-full'>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-700 text-[10px] uppercase font-bold">{lable}</p>
                <p className="text-black text-[9px]">{copyright}</p>
            </div>
            <div className="flex items-center gap-2 px-2 mt-1">
                <IoCaretBackCircle
                    onClick={() => navigate(-1)}
                    className="h-8 w-8 cursor-pointer"
                />
                <input
                    type="text"
                    placeholder="Search for Hash Tags"
                    className="w-full h-8 px-3 border-none transition-all duration-200 rounded-full focus:outline-none bg-[#dedede] text-black "
                    onFocus={() => navigate("/hashtag/search")}
                    value={`#${tagname}`}
                />
            </div>
            <hr className="my-1 h-[0.5px] bg-gray-400 border-0" />
            <div className="sticky top-0 bg-white z-50">
                <div className="grid grid-cols-12 bg-white mt-1 px-2 sticky top-0 z-50">
                    <div className="col-span-12">
                        <div className="px-1">
                            <Carousel
                                arrows={true}
                                swipeable={true}
                                draggable={true}
                                showDots={false}
                                responsive={responsive}
                                ssr={true}
                                infinite={false}
                                slidesToSlide={1}
                                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                className="gap-2 flex"
                            >
                                {Category?.map((data) => (
                                    <div className={`w-auto px-[3px] mx-1 py-[3px] cursor-pointer my-1 text-center text-gray-800 ${activeTab === data.name ? "border-b-2 border-black" : "bg-black rounded-full text-white"}`} key={data.id}>
                                        <p
                                            onClick={() => setActiveTab(data.name)}
                                            className={`cursor-pointer text-[12px] px-1`}
                                        >
                                            {data.name}
                                        </p>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
                <hr className="w-full mt-[2px] mb-[2px] h-[0.5px] bg-[#c9c9c9] border-0" />
            </div>
            {activeTab === "For You" && <div className='px-2' >
                <ForYou posts={hashtag.data.posts} loading={hashtag.loading} />
            </div>}
            {activeTab === "Tags" && <div className='px-2' >
                <Tags tags={hashtag.data.posts} loading={hashtag.loading} />
            </div>}
        </div>
    )
}
