import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserNotifications, readNotifications } from "../../redux/apislice/notificationsSlice";
import { TimeFormat } from "../../hooks/TimeFormat";
import { useNavigate } from "react-router-dom";

const Reviews = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate()
   const notifications1 = useSelector(state => state.notifications)
   const { loading, data } = notifications1;
   useEffect(() => {
      dispatch(fetchUserNotifications())
   }, [dispatch])

   return (
      <div className="mt-2 " >
         <p className="font-bold text-[13px]">Today</p>
         {data?.map((item) => {
            const date = TimeFormat(item.created_at)
            if (!item.is_read) {
               dispatch(readNotifications(item.id)).then((res) => {
                  if (res.error) {
                     console.log(res.error, "error")
                  } else {
                     dispatch(fetchUserNotifications())
                  }
               })
            }
            return (
               <div className="grid grid-cols-12 mt-2 items-center" key={item.id}>
                  <img
                     alt="img"
                     className="col-span-2 h-7 w-7 rounded-full object-cover cursor-pointer"
                     src={item.created_by?.avatar}
                     onClick={() => navigate(`/otheruser/account/${item?.created_at?.id}`)}
                  />
                  <div className="col-span-10 grid grid-cols-12 items-center">
                     <div className="col-span-12 items-center">
                        <p className="font-bold text-start text-[12px] cursor-pointer" onClick={() => navigate(`/otheruser/account/${item?.created_at?.id}`)}>{item.created_by.first_name} {item.created_by.last_name}</p>
                     </div>
                     <div className="col-span-12 grid grid-cols-12  items-center">
                        <p className="col-span-9 text-start text-[12px] cursor-pointer" onClick={() => navigate(`/postview/${item.post}`)}>{item.message}</p>
                        <p className="col-span-3 text-[8px] font-bold text-[#00A3FF] text-end">{date}</p>
                     </div>
                  </div>
               </div>
            )
         }
         )}
         {data?.length === 0 && <p className="text-center">No Notification Found</p>}
      </div>
   )
}

export default Reviews